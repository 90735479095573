<template>
	<main>
		<HeaderTab
			:title="$t('intra_location.titre')"
		/>

 		<div id="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
						<div class="box">
							<CustomTable
								id_table="intra_location"
								ref="intraLocationTable"
								primaryKey="uuid"
								:busy.sync="table_busy"
								:items="items"
							/>

							<ModalReorganizeIntraLocation 
								ref="modalReorganize"
								:refresh_table.sync="refresh_table"
								:intralocation="reorganize_selected"
								:parents="parents"
							/>
						</div>
                    </div>
                </div>
            </div>
 		</div>

		<AddModal ref="addModal" @ok="init_component" />
	</main>
</template>

<script>
import IntraLocation from '@/mixins/IntraLocation'
import Navigation from '@/mixins/Navigation'

export default {
	name: 'IntraLocation',
	mixins: [IntraLocation, Navigation],
	data() {
		return {
			table_busy: true,
			items: null,
			reorganize_selected: {},
	        refresh_table: false,
			parents: false,
			events_tab: {
				"TableAction::goToAddIntraLocation": this.addIntraLocation,
				"TableAction::goToEditIntraLocation": this.editIntraLocation,
				"TableAction::goToDeleteIntraLocation": this.goToDeleteIntraLocation,
				'TableAction::goToReorganizeChildren': (loc) => {
	            	this.reorganize_selected = loc[0]
					this.parents = false
	            	this.openModalReorganize()
	            },
	            'TableAction::goToReorganizeParent': () => {
	            	this.reorganize_selected = {children: this.items.filter(loc => loc.intralocation_parent == 0)}
					this.parents = true
	            	this.openModalReorganize()
	            },
			}
		}
	},
	components: {
		HeaderTab: () => import('@/components/HeaderTab'),
		CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
		AddModal: () => import('@/components/IntraLocation/AddModal'),
		ModalReorganizeIntraLocation: () => import('@/components/IntraLocation/ModalReorganizeIntraLocation')
	},
	mounted() {
		this.init_component()
	},
	methods: {
		async init_component() {
			this.table_busy = true
			this.reorganize_selected = {}
			this.table_busy = true
			this.items = await this.getIntraLocationsWithresidences()
			this.refreshTable()
			this.table_busy = false
		},
		addIntraLocation() {
			this.$refs.addModal.openModal()
		},
		editIntraLocation(locations) {
			this.$refs.addModal.openModal(locations[0])
		},
		async goToDeleteIntraLocation(location) {
			await this.deleteIntraLocation(location)
			this.successToast('toast.info_save_succes')
			this.init_component()
		},
		openModalReorganize() {
			this.$refs.modalReorganize.openModal()
		},
		refreshTable() {
			if(this.$refs.intraLocationTable) {
				this.$refs.intraLocationTable.resetCachedItems()
			}
		}
	},
	watch: {
		async refresh_table() {
			await this.init_component()
		}
	},
}
</script>